export enum Spec {
  AddToCart = 'specs.siteSearch.AddToCart',
  Facets = 'specs.siteSearch.Facets',
  MobileSuggestions = 'specs.siteSearch.MobileSuggestions',
  OnlinePrograms = 'specs.siteSearch.OnlinePrograms',
  RemoveLabelOverrideToggle = 'specs.siteSearch.RemoveLabelOverrideToggle',
  RenderSeoTags = 'specs.siteSearch.RenderSeoTags',
  ShorterSearchTerm = 'specs.siteSearch.EnableShorterSearchTermQuery',
  UseEmptyImages = 'specs.siteSearch.UseEmptyImages',
  ViewAllButtonDesign = 'specs.siteSearch.ViewAllButtonDesign',
  WidgetRtl = 'specs.siteSearch.WidgetRtl',
}
