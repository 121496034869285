import { debounce } from 'lodash';
import { IWidgetController } from '@wix/native-components-infra/dist/src/types/types';

import {
  ISearchResultsWixCode,
  IWidgetControllerConfig,
} from '../../../../lib/platform.types';
import { createSearchPlatformBiLogger } from '../bi';
import { convertResponseToSuggestions } from './convertResponseToSuggestions';
import {
  I$WEvent,
  I$WResult,
  ISearchBoxWixCode,
} from './searchAppControllerFactory.types';
import { Spec } from '../../../../lib/specs';

export async function searchAppControllerFactory(
  controllerConfig: IWidgetControllerConfig,
): Promise<IWidgetController> {
  const {
    buildSearchAllUrl,
    getCategoryList,
    platformAPIs,
    searchLocation,
    searchSDK,
    wixCodeApi,
    flowAPI,
  } = controllerConfig;

  const { $w } = controllerConfig;
  const { formFactor, viewMode } = wixCodeApi.window;
  const isMobile = formFactor === 'Mobile';
  const isSiteViewMode = viewMode === 'Site';
  const isDemoContent = !isSiteViewMode;
  const { experiments, reportError } = flowAPI;

  const useModalSuggestions =
    isMobile && experiments.enabled(Spec.MobileSuggestions);

  const bi = createSearchPlatformBiLogger(platformAPIs, reportError);

  function getRelativeUrl(url: string): string {
    const relativeUrl = url.replace(wixCodeApi.location.baseUrl, '');
    return relativeUrl === '' ? '/' : relativeUrl;
  }

  return {
    async pageReady() {
      const absoluteUrl = await searchLocation.getSearchResultsAbsoluteUrl();

      const searchBoxes: I$WResult<ISearchBoxWixCode> = $w('@searchBox');
      const searchResults: I$WResult<ISearchResultsWixCode> = $w(
        '@searchResults',
      );

      const hasSearchBoxOnPage = searchBoxes.length > 0;
      const isOnSearchResultsPage = searchResults.length > 0;

      if (!hasSearchBoxOnPage) {
        return;
      }

      const previousSearchBoxValueById: Record<
        string,
        string | undefined
      > = searchBoxes.reduce(
        (result, { uniqueId, value }) => ({
          ...result,
          [uniqueId]: value,
        }),
        {},
      );

      searchBoxes.onClear((e) => {
        bi.resetRequestCorrelationId();

        // Multiple bugs in SearchBox Velo SDK clear functionality.
        // Remove this when fixed: https://jira.wixpress.com/browse/SER-2067
        if (e) {
          previousSearchBoxValueById[e.target.uniqueId] = e.target.value;
        }

        // TODO: trigger biEvent here.
        // https://jira.wixpress.com/browse/SER-1345
      });

      searchBoxes.onSubmit((e) => {
        const searchBox = e.target;
        const searchQuery = searchBox.value;

        bi.searchSubmit({
          isDemoContent,
          searchQuery,
        });

        // NOTE: Ugly workaround for some websites where controller is created and attached
        // to the same SearchBox component multiple times.
        setTimeout(() => {
          searchBox.value = '';
        }, 0);

        searchBox.suggestions = null;

        if (!isOnSearchResultsPage) {
          // NOTE: navigation with not empty search query works correctly only in `Site` view mode
          // TODO: we could navigate with searchQuery in preview mode when `sv_editorNativeComponents` opened
          void searchLocation.navigateToSearchResults({
            query: isSiteViewMode ? searchQuery : '',
          });
        } else {
          // NOTE: editor version of searchResults component, doesn't return valid controller instance
          // so `changeQuery` call does not affect search results component
          // should be fixed when `sv_editorNativeComponents` opened
          // https://github.com/wix-private/site-search/issues/130
          searchResults.changeQuery(searchQuery);
        }
      });

      searchBoxes.onFocus((e) => {
        bi.searchBoxFocused({
          isDemo: isDemoContent,
        });
        if (!isDemoContent && useModalSuggestions) {
          e.target.openSuggestions();
        }
      });

      searchBoxes.onChange(({ target }) => {
        const { value, uniqueId } = target;
        const previousValue = previousSearchBoxValueById[uniqueId] ?? '';

        if (!previousValue.length && value.length > 0) {
          bi.searchBoxStartedWritingAQuery({
            isDemo: isDemoContent,
          });
        }

        previousSearchBoxValueById[uniqueId] = value;
      });

      if (!isDemoContent && (!isMobile || useModalSuggestions)) {
        const loadSuggestions = (minimumSearchTermLength: number) => async (
          e: I$WEvent<ISearchBoxWixCode>,
        ) => {
          const searchBox = e.target;
          const searchQuery = searchBox.value;

          if (!searchBox.suggestionsEnabled) {
            return;
          }

          if (!searchQuery) {
            bi.resetRequestCorrelationId();
          }

          if (useModalSuggestions && searchQuery.trim() === '') {
            searchBox.suggestions = null;
            return;
          }

          if (
            searchQuery.length < minimumSearchTermLength &&
            !useModalSuggestions
          ) {
            searchBox.suggestions = null;
            return;
          }

          const biSearchBoxSuggestionsRequestFinished = bi.searchBoxSuggestionsRequestStarted(
            { searchQuery },
          );

          try {
            const federatedResponse = await searchSDK.getFederatedSuggestions({
              query: searchQuery,
              limit: 4,
            });
            const categoryList = await getCategoryList();

            const federatedSuggestions = convertResponseToSuggestions({
              federatedResponse,
              categoryList,
              searchQuery,
              footerUrl: await buildSearchAllUrl(searchQuery),
              searchLocation,
              absoluteUrl,
            });

            if (searchQuery === searchBox.value) {
              searchBox.suggestions = federatedSuggestions;
            }

            biSearchBoxSuggestionsRequestFinished({
              success: true,
              suggestions: federatedSuggestions.items,
            });
          } catch (error: any) {
            biSearchBoxSuggestionsRequestFinished({
              success: false,
              error: error.toString(),
              suggestions: [],
            });

            reportError(error);

            throw error;
          }
        };

        const isShorterSearchTermEnabled = experiments.enabled(
          Spec.ShorterSearchTerm,
        );

        searchBoxes.onChange(
          debounce(
            loadSuggestions(isShorterSearchTermEnabled ? 1 : 3),
            isShorterSearchTermEnabled ? 100 : 300,
          ),
        );

        searchBoxes.onSubmit((e) => {
          const searchBox = e.target;
          searchBox.suggestions = null;
        });

        searchBoxes.onSuggestionSelect((e) => {
          const searchBox = e.target;
          const { title, data } = e.syntheticEvent;
          const { url, query, globalIndex, groupId, type } = data;

          if (type === 'item') {
            bi.searchBoxSuggestionClick({
              title,
              url,
              searchQuery: query,
              index: globalIndex,
              documentType: groupId,
              suggestions: searchBox.suggestions?.items || [],
            });
          }
          // Compatibility with Bolt component (clicking on a suggestions group uses onSuggestionSelect()
          // in Bolt and onSuggestionGroupSelect() in Thunderbolt).
          else {
            bi.searchBoxSuggestionShowAllClick({
              searchQuery: query,
              documentType: groupId,
            });
          }

          searchBox.suggestions = null;

          wixCodeApi.location.to?.(getRelativeUrl(url));
        });

        searchBoxes.onSuggestionsFooterClick((e) => {
          const query = e.target.value;
          bi.searchBoxSuggestionSearchAllClick({
            searchQuery: query,
          });
          searchBoxes.suggestions = null;
          searchLocation.navigateToSearchResults({ query }).catch(reportError);
        });

        searchBoxes.onSuggestionGroupSelect((e) => {
          /* SearchBox::Bolt does not use `onSuggestionGroupSelect`:
           * https://github.com/wix-private/wix-ui-santa/search?q=onSuggestionGroupSelect
           * https://github.com/wix-private/site-search/search?q=handleSuggestionGroupSelect
           *
           * However old implementation of API leaked to the users at least once.
           *
           * In Bolt implementation of SearchBox, fields (url, query, groupId) were defined
           * directly on `syntheticEvent` (without `data` layer).
           *
           * In SearchBox::TB we moved to unified solution and introduced `syntheticEvent.data`.
           *
           * This guard should help to avoid unexpected failures in the user code.
           */
          if (!e.syntheticEvent?.data) {
            return;
          }

          const { url, query, groupId } = e.syntheticEvent.data;
          searchBoxes.suggestions = null;

          bi.searchBoxSuggestionShowAllClick({
            searchQuery: query,
            documentType: groupId,
          });

          const relativeUrl = url.replace(wixCodeApi.location.baseUrl, '');
          wixCodeApi.location.to?.(relativeUrl);
        });
      }
    },
  };
}
